/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content{
    --ion-background-color:#385D41;
    --offset-bottom: 64px !important;
}

ion-item{
    --color: white;
    --background: transparent;
    --border-color: white;
}

.item.item-input {
    background-color: transparent;
}

.popover-content.sc-ion-popover-md {
    background-color: #27412D;
}

h1{
    text-align: center;
    color: white;
}

h3{
    color: white;
}

h5{
    color: white;
}

.category-header{
    text-align: center;
    color: white;
}

.header-color{
    --background: #27412D;
}

.footer{
    background-color: #27412D;
}

.body-container{
    height: 100%;
    width: 100%;
    display: flex;
}

ul.breadcrumb {
    padding-left: 16px;
    margin-bottom: 0px;
    list-style: none;
}

ul.breadcrumb li {
    display: inline;
    font-size: 18px;
    color: #27412D;
}

ul.breadcrumb li+li:before {
    padding-left: 5px;
    color: white;
    content: "/\00a0";
}

  /* Add a color to all links inside the list */
ul.breadcrumb li a {
    color: white;
    text-decoration: none;
}

ul.breadcrumb li a:hover {
    color: white;
    text-decoration: underline;
}

@media (max-width: 576px) {
    .hide-mobile{
        display: none;
    }
}

.content-text-container {
    padding: 16px;
}

p{
    color: white;
}

.text-align-center{
    text-align: center;
}

th{
    background-color: #27412D;
    border: 1px solid white;
}
.table-side{
    background-color: #27412D;
    border: 1px solid white;
    text-align: center;
}
td{
    border: 1px solid white;
    padding: 8px;
}

ul{
    color: white;
}

ol{
    color: white;
}

table{
    width: 100%;
}

.fixed-width{
  table-layout: fixed
}

.table-corner{
    width: 10%;
}

.table-header-22{
    width: 22%;
}

.table-header-25{
    width: 25%;
}

.table-header-30{
    width: 30%;
}

.table-header-45{
    width: 45%;
}

.table-header-50{
    width: 50%;
}

.custom-modal{
    --border-radius: 16px;
    --width: 65%;
    --height: 80%;
}

ion-row{
    overflow-y: scroll;
    padding-bottom: 64px;
}

.row-no-padding{
    overflow: hidden !important;
    padding-bottom: 0px !important;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.ion-page{
  justify-content: unset !important;
}

.breadcrumb{
    background-color: #385D41;
}
